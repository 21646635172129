import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

export default function Lastmenu({ lastmenu }) {
  const {t} = useTranslation();
  const { entrepriseRoles } = useContext(AuthContext);
  const shouldDisplayMenu = lastmenu.role
    ? lastmenu.role.some((role) => entrepriseRoles.data.includes(role))
    : true;
    
  return (
    <>
      {shouldDisplayMenu && (
        <li className="lastmenu-item">
          <a href={lastmenu.path && lastmenu.path} className="lastmenu-link">
            {t(lastmenu.title)}
          </a>
        </li>
      )}
    </>
  );
}
