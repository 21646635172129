import React, { useEffect, useMemo, useState } from "react";
import Loader from "./Loader";
import {
  useExpanded,
  useFilters,
  useGroupBy,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { CheckBoxTest } from "./FilterTable";
import { matchSorter } from "match-sorter";
import { useTranslation } from "react-i18next";

/**
 *
 * @param {array} tableData
 * @param {array} tableColumns - model in headerContent file
 * @param {component} rowComponent - if row have an accordion
 * @param {boolean} checkbox - if row has an checkbox set to true
 * @param {array} sites - list of entreprise's sites
 * @param {function} setSelectedIncidents - function to keep selected incidents whith checkbox
 * @param {array} licences
 * @param {array} holding
 * @param {array} allRoles
 * @param {date} date - date calendar auvik
 */
export default function TableComponent({
  tableData,
  tableColumns,
  rowComponent: RowComponent,
  checkbox,
  sites,
  setSelectedIncidents,
  licences,
  holding,
  allRoles,
  date,
  setState,
  state,
  stateQueryRow,
  setStateQueryRow,
  referrer
}) {
  const { t } = useTranslation();

  // Handle button to show table filter
  const [isFilter, setIsFilter] = useState(false);
  // Save selected rows to update incidents
  const [selectedRows, setSelectedRows] = useState([]);

  // Table data and columns header
  const data = useMemo(() => (tableData ? tableData : []), [tableData]);
  const columns = useMemo(() => {
    if (holding === 27 && (referrer === "equipements" || referrer === "visio")) {
      return tableColumns.filter(
        (col) => col.Header !== "BU" && col.Header !== "Site"
      );
    }
    
    // Sinon, on garde toutes les colonnes
    return tableColumns;
  }, [tableColumns, holding, referrer]);

  // Function and default column to filter table by column
  const matchSorterFn = (rows, id, filterValue) => {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  };

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: "",
    }),
    []
  );
  const filterTypes = useMemo(
    () => ({
      rankedMatchSorter: matchSorterFn,
    }),
    []
  );

  // Usetable from react table v7
  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      // add an expanded column if they are a row component
      if (RowComponent) {
        hooks.visibleColumns.push((columns) => [
          ...columns,
          {
            id: "expander", // Make sure it has an ID
            Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
              <span {...getToggleAllRowsExpandedProps()}>
                {isAllRowsExpanded ? (
                  <FontAwesomeIcon icon={faChevronDown} />
                ) : (
                  <FontAwesomeIcon icon={faChevronUp} />
                )}
              </span>
            ),
            Cell: ({ row }) => (
              <span
                {...row.getToggleRowExpandedProps({
                  style: {
                    paddingLeft: `${row.depth * 2}rem`,
                  },
                })}
              >
                {row.isExpanded ? (
                  <FontAwesomeIcon icon={faChevronDown} />
                ) : (
                  <FontAwesomeIcon icon={faChevronUp} />
                )}
              </span>
            ),
          },
        ]);
      }

      // add a checkbox column for incidents activation
      if (checkbox === true) {
        hooks.visibleColumns.push((columns) => [
          ...columns,
          {
            id: "selection",
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <CheckBoxTest {...getToggleAllPageRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <CheckBoxTest {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
        ]);
      }
    }
  );

  // import all methods from react-table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    state: { pageIndex, pageSize, selectedRowIds },
  } = tableInstance;
  
  // find selected rows
  useEffect(() => {
    if (checkbox === true) {
      const selectedRows = Object.keys(selectedRowIds).map((id) =>
        tableInstance.rows.find((row) => row.id === id)
      );
      setSelectedRows(selectedRows);
    }
  }, [selectedRowIds, checkbox]);

  // get data from selected rows to update incidents
  useEffect(() => {
    if (checkbox === true) {
      const selectedRowData =
        selectedRows.length > 0 ? selectedRows.map((row) => row.original) : [];
      setSelectedIncidents(selectedRowData);
    }
  }, [selectedRows, checkbox]);

  return (
    <div className="page-table">
      {data ? (
        <>
          <button
            onClick={() => setIsFilter(!isFilter)}
            className="button button-new table-filter_button"
          >
            {t("Filtrer")}
          </button>
          <TableContainer>
            <Table size="small" {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup) => {
                  const { key: headerGroupKey, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
                  return (
                    <TableRow key={headerGroupKey} {...restHeaderGroupProps}>
                      {headerGroup.headers.map((column) => {
                        const { key: columnKey, ...restColumnProps } = column.getHeaderProps(column.getSortByToggleProps());
                        return (
                          <TableCell key={columnKey} {...restColumnProps}>
                            {column.icon && <FontAwesomeIcon icon={column.icon} className="table-icon" />}
                            {column.id !== "expander" && column.id !== "selection"
                              ? t(column.render("Header"))
                              : column.render("Header")}
                            <div>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <FontAwesomeIcon icon={faChevronDown} className="table-icon_filter" />
                                ) : (
                                  <FontAwesomeIcon icon={faChevronUp} className="table-icon_filter" />
                                )
                              ) : (
                                ""
                              )}
                            </div>
                            <div>
                              {isFilter && (column.canFilter ? column.render("Filter") : null)}
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  // Extract the key from the rest of the row props
                  const { key: rowKey, ...restRowProps } = row.getRowProps();
                  return (
                    <React.Fragment key={row.id}>
                      {row.original.message ? (
                        <TableRow key={row.id}>
                          <TableCell colSpan={visibleColumns.length}>
                            {row.original.message}
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow {...restRowProps} className={`table-row ${row.isExpanded ? "table-row_expanded" : ""}`}>
                          {row.cells.map((cell) => {
                            const { key: cellKey, ...restCellProps } = cell.getCellProps();
                            return (
                              <TableCell key={cellKey} {...restCellProps} className="table-row_cell">
                                {cell.render("Cell")}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      )}
                      {row.isExpanded && (
                        <TableRow className="table-row_expanded_accordion">
                          <TableCell colSpan={visibleColumns.length}>
                            <RowComponent
                              item={row.original}
                              date={date}
                              sites={sites}
                              referrer={referrer}
                              licences={licences}
                              allRoles={allRoles}
                              holding={holding}
                              setState={setState}
                              state={state}
                              stateQueryRow={stateQueryRow}
                              setStateQueryRow={setStateQueryRow}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="table-pagination">
            <div className="table-filter_select-container">
              <select
                className="table-filter_select"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {t("Montrer")}
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="button button-new"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="button button-new"
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
              <span>
                Page
                <strong>
                  {" "}
                  {pageIndex + 1} {t("sur")} {pageOptions.length}
                </strong>
              </span>
            </div>
            <div>
              <span>
                {t("Allez à la page")}:
                <input
                  className="table-filter_input"
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  style={{ width: "100px" }}
                />
              </span>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
