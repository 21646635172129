import {
  faBoxOpen,
  faBarcode,
  faLocationDot,
  faCircleCheck,
  faClockRotateLeft,
  faBuilding,
  faNetworkWired,
  faCertificate,
  faCircleQuestion,
  faAddressCard,
  faCircleInfo,
  faClock,
  faEye,
  faStopwatch,
  faTag,
  faTriangleExclamation,
  faCalendar,
  faComputer,
  faEthernet,
  faGlobe,
  faSatellite,
  faShield,
  faSignal,
  faCheck,
  faUser,
  faDesktop,
  faGears,
  faPlug,
  faQuestionCircle,
  faPaperPlane,
  faBox,
  faInfoCircle,
  faClipboardUser,
  faLock,
  faHourglassEnd,
  faDoorOpen,
  faDoorClosed,
  faHandHolding,
  faCircleXmark,
  faHourglass,
  faKey,
  faPenNib,
  faWifi,
  faFile,
  faMapMarkerAlt,
  faHouseUser,
  faWindowMaximize,
  faSatelliteDish,
  faEyeSlash,
  faUmbrella,
  faLocation,
  faSignature,
  faCircleUser,
  faEnvelope,
  faLayerGroup,
  faHouse,
  faMessage,
  faGear,
  faPlugCircleCheck,
  faDisplay,
  faEarthEurope,
  faLaptop,
  faPencil,
  faPeopleRoof,
  faWarning,
  faTablet,
  faPhone,
  faMobilePhone,
  faPrint,
  faFileArchive,
  faDollar,
  faHourglassHalf,
  faXmark,
  faLink
} from "@fortawesome/free-solid-svg-icons";
import { TextSearchFilter, DropdownFilter } from "./FilterTable";
import IconStatus from "./IconStatus";
import { convertDateAndReturn, elpasedTimeAndReturn, timeRemaining, calculateYearsDifference } from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faWindows } from "@fortawesome/free-brands-svg-icons";
import MultipleStatus from "./MultipleStatus";
import IconsStatus from "./IconStatus";
import paloLogo from "../assets/palo-logo.png";
import { useTranslation } from "react-i18next";

// Reset value of status for DB
const statusValues = [
  { value: "1", label: "En cours" },
  { value: "2", label: "En attente fournisseur" },
  { value: "4", label: "En attente client" },
  { value: "5", label: "Problème résolu" },
];

export const LogsMonitoringColumns = [
  {
    accessor: "prenom",
    Header: "prénom",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faUser,
  },
  {
    accessor: "nom",
    Header: "Nom",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faUser
  },
  {
    accessor: "extra.userData.email",
    Header: "email",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faEnvelope,
  },
  {
    accessor: "extra.userData.entreprise",
    Header: "entreprise",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faBuilding,
  },
  {
    accessor: "log",
    Header: "message",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faMessage,
  },
  {
    accessor: "extra.clientIp",
    Header: "ip",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faNetworkWired,
  },
  {
    accessor: "extra.port",
    Header: "port",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faNetworkWired,
  },
  {
    accessor: "extra.basePath",
    Header: "chemin",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faLink,
  },
  {
    accessor: "extra.baseUrl",
    Header: "url",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faLink,
  },
  {
    accessor: "created_at",
    Header: "date de création",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faClock,
  }
];

// Equipements
export const Equipementscolumns = [
  {
    Header: "N° de série",
    accessor: "serial_number",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faBarcode,
  },
  {
    Header: "Nom",
    accessor: "device_name",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
  },
  {
    Header: "Produit",
    accessor: "famille.product",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faBoxOpen,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (item.famille !== null) {
        return (
          <p className="table-row_cell-column">
            <img
              src={
                item.famille.constructeur === "CISCO"
                  ? "./assets/cisco_logo.png"
                  : item.famille.constructeur === "PALOALTO"
                    ? "./palo_logo.png"
                    : item.famille.constructeur === "UCOPIA"
                      ? "./assets/ucopia_logo.png"
                      : null
              }
              className="table-row_cell-img"
            />
            {item.famille.product}
          </p>
        )
      } else {
        return ""
      }
    },
  },
  {
    Header: "IP",
    accessor: "ip",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faNetworkWired,
  },
  //Masquer BU et Site pour Demo
  {
    Header: "BU",
    accessor: "bu",
    Filter: DropdownFilter,
    icon: faBuilding,
  },
  {
    Header: "Site",
    accessor: "site.nom",
    Filter: DropdownFilter,
    icon: faLocation,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (typeof item.site === "string") {
        return item.site
      } else if (item.site) {
        return item.site.nom
      } else {
        "Not found"
      }
    },
  },
  {
    Header: "Sous contrat",
    accessor: "is_covered",
    Filter: DropdownFilter,
    icon: faCircleCheck,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.is_covered && item.is_covered === "OUI" ? (
        <IconStatus
          value={item.is_covered}
          text={convertDateAndReturn(item.date_end_covered)}
        />
      ) : item.is_covered ? (
        <IconStatus value={item.is_covered} color={new Date(item.date_end_covered) > new Date() ? "green" : "red"}
        />
      ) : (
        <IconStatus value={"Non défini"} />
      );
    },
  },
  {
    Header: "Fin de support",
    accessor: "famille.end_of_support",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (item.famille) {
        if (item.famille.end_of_support) {
          const endOfSupportDate = new Date(item.famille.end_of_support);
          const year = endOfSupportDate.getFullYear();
          const value = year === 1970 ? "Non annoncé" : convertDateAndReturn(item.famille.end_of_support);
          const color = year === 1970 ? "orange" : endOfSupportDate > new Date() ? "green" : "red";
          return <IconStatus value={value} color={color} />;
        } else {
          return <IconStatus value={"Non défini"} />;
        }
      } else {
        return <IconStatus value={"Non défini"} />;
      }
    },
    icon: faClockRotateLeft,
  },
  {
    Header: "Source",
    accessor: "site.source",
    Filter: DropdownFilter,
    icon: faLocation,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (item.source) {
        return item.source
      } else {
        "Not found"
      }
    },
  },
];


export const Contractscolumns = [
  {
    Header: "Numéro du contrat",
    accessor: "contract_number",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
  },
  {
    Header: "Status",
    accessor: "contract_lineStatus",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faCircleQuestion,
    Cell: ({ cell }) => {
      const contract = cell.row.original;
      return <IconsStatus value={contract.contract_lineStatus} />

    },
  },
  // {
  //   Header: "Client",
  //   accessor: "endCustomerName",
  //   Filter: DropdownFilter,
  //   filter: "rankedMatchSorter",
  //   icon: faUser,
  // },
  {
    Header: "Début du contrat",
    accessor: "startDate",
    icon: faClockRotateLeft,
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
  },
  {
    accessor: "endDate",
    Header: "Expiration du contrat",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faHourglassEnd,
    Cell: ({ cell }) => {
      const contract = cell.row.original;
      if (contract.endDate) {
        return contract.endDate ?
          <IconsStatus value={contract.endDate} color={new Date(contract.endDate) > new Date() ? "green" : "red"}
          /> :
          <IconStatus value={"Non défini"} />;
      } else {
        return <IconStatus value={"Non défini"} />;
      }
    },
  },
  {
    accessor: "warrantyEndDate",
    Header: "Garantie constructeur",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faShield,
  },
  {
    accessor: "serviceDescription",
    Header: "Niveau de services",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faHandHolding,
  },
];

export const Incidentscolumns = [
  {
    Header: "N° de ticket",
    accessor: "ticketnumber",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faBarcode,
  },
  {
    Header: "Type",
    accessor: "casetypecode",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.casetypecode ? (
        <IconStatus value={item.casetypecode} />
      ) : null;
    },
  },
  {
    Header: "Status",
    accessor: "statuscode",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faCircleQuestion,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      const statusLabel = statusValues.find(
        (status) => status.value === item.statuscode
      )?.label;
      return statusLabel ? (
        <IconStatus value={item.statuscode} ticketText={statusLabel} />
      ) : <IconStatus value={"Recherche avancée"} />;
    },
  },
  {
    Header: "Titre",
    accessor: "title",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faCircleInfo,
  },
  {
    Header: "Sévérité",
    accessor: "prioritycode",
    Filter: DropdownFilter,
    icon: faTriangleExclamation,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.prioritycode ? <IconStatus value={item.prioritycode} /> : null;
    }
  },
  {
    Header: "SLA",
    accessor: "sla",
    Filter: DropdownFilter,
    icon: faStopwatch,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.sla ? <IconStatus value={item.sla} /> : null;
    },
  },
  {
    Header: "Date de création",
    accessor: "createdon",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    Cell: ({ cell: { value } }) => {
      return convertDateAndReturn(value);
    },
    icon: faClock,
  },
  {
    Header: "Suivi par",
    accessor: "owninguser_fullname",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faEye,
  },
];

export const AuvikAlertscolumns = [
  {
    Header: "Nom",
    accessor: "Entity Name",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
  },
  {
    Header: "Type",
    accessor: "Alert name",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faBoxOpen,
  },
  {
    Header: "status",
    accessor: "Status",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faPlugCircleCheck,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.Status ? <IconStatus value={item.Status} /> : null;
    }
  },
  {
    Header: "Sévérité",
    accessor: "Severity",
    Filter: DropdownFilter,
    icon: faTriangleExclamation,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.Severity ? <IconStatus value={item.Severity} /> : null;
    }
  },
  {
    Header: "Détecté le",
    accessor: "Detected On",
    icon: faClock,
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (item["Detected On"]) {
        return item["Detected On"] ?
          convertDateAndReturn(item["Detected On"])
          :
          <IconStatus value={"Non défini"} />;
      } else {
        return <IconStatus value={"Non défini"} />;
      }
    },
  },
]

export const Auvikcolumns = [
  {
    Header: "Nom",
    accessor: "deviceName",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
  },
  {
    Header: "Type",
    accessor: "deviceType",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faBoxOpen,
  },
  // {
  //   Header: "Ip",
  //   accessor: "ipAddresses",
  //   Filter: TextSearchFilter,
  //   filter: "rankedMatchSorter",
  //   icon: faNetworkWired,
  //   Cell: ({ value }) => (
  //     <span className="ip-address-auvik">
  //       {value.map((ip, index) => (
  //         <span key={index}>
  //           {ip}
  //           {index < value.length - 1 && ", "}
  //         </span>
  //       ))}
  //     </span>
  //   ),
  // },
  {
    Header: "model",
    accessor: "makeModel",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faCircleQuestion,
  },
  // {
  //   Header: "status",
  //   accessor: "onlineStatus",
  //   Filter: TextSearchFilter,
  //   filter: "rankedMatchSorter",
  //   icon: faPlugCircleCheck,
  //   Cell: ({ cell }) => {
  //     const item = cell.row.original;
  //     return item.onlineStatus ? <IconStatus value={item.onlineStatus} /> : null;
  //   }
  // },
  {
    Header: "Serie",
    accessor: "serialNumber",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faDisplay,
  },
];

export const Webexcolumns = [
  {
    Header: "Nom",
    accessor: "name",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
  },
  {
    Header: "Statuts",
    accessor: "status",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faPlugCircleCheck,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.status && <div className="status-intable"><IconStatus value={item.status} /></div>;
    },
  },
  {
    Header: "Produit",
    accessor: "product",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faDisplay,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (item.product.includes("B&O")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_b&o.svg" /> {item.product}</p>
      } else if (item.product === "Cisco Room Navigator") {
        return <p className="table-cell_img"> <img src="./assets/cisco_roomnavigator.svg" /> {item.product} </p>
      } else if (item.product.includes("Cisco Room Kit")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_roomkit.jpeg" /> {item.product} </p>
      } else if (item.product.includes("Cisco Codec")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_codecplus.jpeg" /> {item.product} </p>
      } else if (item.product.includes("Cisco 322")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_322.png" /> {item.product} </p>
      } else if (item.product.includes("Cisco 730")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_730.png" /> {item.product} </p>
      } else if (item.product.includes("Cisco Board")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_board55.svg" /> {item.product} </p>
      } else if (item.product.includes("Cisco Room Bar")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_roombar.jpeg" /> {item.product} </p>
      } else if (item.product.includes("Cisco Webex DX80")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_dx80.svg" /> {item.product} </p>
      } else if (item.product.includes("Cisco Webex Share")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_webexshare.png" /> {item.product} </p>
      } else if (item.product.includes("Cisco Desk Camera")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_deskcamera.svg" /> {item.product} </p>
      } else if (item.product.includes("Cisco 562")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_562standard.png" /> {item.product} </p>
      } else if (item.product.includes("Cisco TelePresence MX800") || item.product.includes("Cisco TelePresence MX700")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_telepresencemx800.png" /> {item.product} </p>
      } else if (item.product.includes("Cisco TelePresence MX300")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_mx300.jpeg" /> {item.product} </p>
      } else if (item.product.includes("Cisco TelePresence SX20")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_sx20.svg" /> {item.product} </p>
      } else if (item.product.includes("Cisco TelePresence SX80")) {
        return <p className="table-cell_img"> <img src="./assets/cisco-sx80.png" /> {item.product} </p>
      } else if (item.product.includes("Cisco Desk Pro")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_deskpro.svg" /> {item.product} </p>
      } else if (item.product.includes("Cisco Room 55")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_room55.svg" /> {item.product} </p>
      } else if (item.product.includes("Cisco Webex DX70")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_dx70.jpeg" /> {item.product} </p>
      } else if (item.product.includes("Cisco Desk Mini")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_deskmini.png" /> {item.product} </p>
      } else if (item.product.includes("Cisco Room 70")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_room70.svg" /> {item.product} </p>
      } else if (item.product.includes("Cisco 722")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_722.jpeg" /> {item.product} </p>
      } else if (item.product.includes("Cisco 8841")) {
        return <p className="table-cell_img"> <img src="./assets/cisco-8841.png" /> {item.product} </p>
      } else if (item.product.includes("Cisco 8845")) {
        return <p className="table-cell_img"> <img src="./assets/cisco-8845.png" /> {item.product} </p>
      } else if (item.product.includes("Cisco 7841")) {
        return <p className="table-cell_img"> <img src="./assets/cisco_7841.png" /> {item.product} </p>
      } else if (item.product.includes("Cisco 8865")) {
        return <p className="table-cell_img"> <img src="./assets/cisco-8865.png" /> {item.product} </p>
      } else if (item.product.includes("Cisco 7821")) {
        return <p className="table-cell_img"> <img src="./assets/cisco-7821.png" /> {item.product} </p>
      }
      else {
        return item.product
      }
    },
  },
  {
    Header: "IP",
    accessor: "ip",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faNetworkWired,
  },
];

export const MerakiDevicecolumns = [
  {
    Header: "Nom",
    accessor: "name",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
  },
  {
    Header: "Modèle",
    accessor: "model",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faCircleQuestion,
  },
  {
    Header: "Numéro de série",
    accessor: "serial",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faBarcode,
  },
  {
    Header: "Mac",
    accessor: "mac",
    icon: faNetworkWired,
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
  },
  {
    accessor: "status",
    Header: "Status",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faCircleInfo,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.status && <IconStatus value={item.status} />;
    },
  },
  {
    accessor: "productType",
    Header: "Type de produit",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faBoxOpen,
  },
  {
    accessor: "publicIp",
    Header: "Ip publique",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faWifi,
  },
  {
    accessor: "lastReportedAt",
    Header: "Dernier statut",
    icon: faFile,
    Cell: ({ cell }) => {
      const { t } = useTranslation();
      const item = cell.row.original;
      return t(elpasedTimeAndReturn(item.lastReportedAt));
    },
  },
];

export const MerakiAllDevicesColumns = [
  {
    Header: "Nom",
    accessor: "name",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
  },
  {
    Header: "Modèle",
    accessor: "model",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faCircleQuestion,
  },
  {
    Header: "Numéro de série",
    accessor: "serial",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faBarcode,
  },
  {
    Header: "Mac",
    accessor: "mac",
    icon: faNetworkWired,
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
  },
  {
    Header: "Network",
    accessor: "networkName",
    icon: faGlobe,
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
  },
  {
    accessor: "status",
    Header: "Status",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faCircleInfo,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.status && <IconStatus value={item.status} />;
    },
  },
  {
    accessor: "productType",
    Header: "Type de produit",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faBoxOpen,
  },
  {
    accessor: "publicIp",
    Header: "Ip publique",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faWifi,
  },
  {
    accessor: "lastReportedAt",
    Header: "Dernier statut",
    icon: faFile,
    Cell: ({ cell }) => {
      const { t } = useTranslation();
      const item = cell.row.original;
      return t(elpasedTimeAndReturn(item.lastReportedAt));
    },
  },
];

export const NetworkAlertscolumns = [
  {
    Header: "Catégorie",
    accessor: "category",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faFileArchive,
  },
  {
    Header: "Sévérité",
    accessor: "severity",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faCircleQuestion,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.severity && <IconStatus value={item.severity} />;
    },
  },
  {
    Header: "Type",
    accessor: "type",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
  },
  {
    accessor: "devices",
    Header: "Appareils",
    filter: "rankedMatchSorter",
    icon: faDesktop,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.scope.devices && item.scope.devices[0].name;
    },
  },
];

export const MerakiClientscolumns = [

  {
    Header: "Nom",
    accessor: "description",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (item.description === null) {
        return null;
      } else if (item.description.includes("SEP")) {
        return <span> <FontAwesomeIcon icon={faPhone} />{" "}{item.description} </span>
      } else if (item.description.includes("LAP")) {
        return <span> <FontAwesomeIcon icon={faLaptop} />{" "}{item.description} </span>
      } else if (item.description.includes("iPhone")) {
        return <span> <FontAwesomeIcon icon={faMobilePhone} />{" "}{item.description} </span>
      } else if (item.description.includes("iPad")) {
        return <span> <FontAwesomeIcon icon={faTablet} />{" "}{item.description} </span>
      } else if (item.description.includes("RICOH")) {
        return <span> <FontAwesomeIcon icon={faPrint} />{" "}{item.description} </span>
      } else {
        return <span> <FontAwesomeIcon icon={faEthernet} />{" "}{item.description} </span>
      }

    },
  },
  {
    Header: "Connecté sur",
    accessor: "recentDeviceName",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faPlug,
  },
  {
    Header: "Fabriquant",
    accessor: "manufacturer",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faHandHolding,
  },
  {
    Header: "Statut",
    accessor: "status",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faCircleQuestion,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.status && <IconStatus value={item.status} />;
    },
  },
  {
    Header: "Mac",
    accessor: "mac",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faNetworkWired,
  },
  {
    Header: "Ip",
    accessor: "ip",
    icon: faWifi,
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
  },
  {
    accessor: "lastSeen",
    Header: "Dernière vue",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faEye,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return elpasedTimeAndReturn(item.lastSeen);
    },
  },
  {
    accessor: "os",
    Header: "OS",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faLaptop,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.os !== null ? item.os : "Autre"
    },
  },
];

export const MerakiLicenceColumn = [
  {
    Header: "Clé",
    accessor: "key",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faKey,
  },
  {
    Header: "Type",
    accessor: "mode",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faCircleInfo,
  },
  {
    Header: "Démarré le",
    accessor: "startedAt",
    icon: faClock,
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (item.startedAt) {
        return item.startedAt ?
          convertDateAndReturn(item.startedAt)
          :
          <IconStatus value={"Non défini"} />;
      } else {
        return <IconStatus value={"Non défini"} />;
      }
    },
  },
  {
    Header: "Demandé le",
    accessor: "claimedAt",
    icon: faClockRotateLeft,
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (item.claimedAt) {
        return item.claimedAt ?
          convertDateAndReturn(item.claimedAt)
          :
          <IconStatus value={"Non défini"} />;
      } else {
        return <IconStatus value={"Non défini"} />;
      }
    },
  },
  {
    Header: "Durée",
    accessor: "expiration",
    icon: faHourglass,
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (item.expiration) {
        return item.expiration ?
          <IconStatus value={convertDateAndReturn(item.expiration)} />
          : "Non défini"
      } else {
        return "Non défini";
      }
    },
  },
  {
    Header: "Expiré",
    accessor: "expired",
    icon: faHourglass,
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return <IconStatus value={item.expired.toString()} color={item.expired.toString() === "true" ? "red" : "green"} />;
    },
  },
  {
    Header: "Invalidé le",
    accessor: "invalidatedAt",
    icon: faCircleXmark,
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (item.invalidatedAt) {
        return item.invalidatedAt ?
          <IconStatus value={convertDateAndReturn(item.invalidatedAt)} />
          : "Non défini"
      } else {
        return "Non défini";
      }
    },
  },
  {
    Header: "Edition",
    accessor: "editions",
    icon: faPenNib,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.editions[0].edition;
    },
  },
];

export const MerakiLicencePerDeviceColumn = [
  {
    Header: "Clé",
    accessor: "key",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faKey,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.licenseKey;
    },
  },
  {
    Header: "Type",
    accessor: "mode",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faCircleInfo,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.licenseType;
    },
  },
  {
    Header: "Duration",
    accessor: "Duration",
    icon: faCircleXmark,
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    Cell: ({ cell }) => {
      const { t } = useTranslation();
      const item = cell.row.original;
      if (item.activationDate && item.expirationDate) {
        return item.activationDate && item.expirationDate ?
          t(calculateYearsDifference(item.activationDate, item.expirationDate))
          : "";
      } else {
        return "";
      }
    },
  },
  {
    Header: "Activation Date",
    accessor: "activationDate",
    icon: faClock,
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (item.activationDate) {
        return item.activationDate ?
          <IconStatus value={convertDateAndReturn(item.activationDate)} />
          : "Non défini"
      } else {
        return "Non défini";
      }
    },
  },
  {
    Header: "Expiration Date",
    accessor: "expirationDate",
    icon: faCircleXmark,
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (item.expirationDate) {
        return item.expirationDate ?
          <IconStatus value={convertDateAndReturn(item.expirationDate)} />
          : "Non défini"
      } else {
        return "Non défini";
      }
    },
  },
  {
    Header: "TimeRemaining",
    accessor: "TimeRemaining",
    icon: faCircleXmark,
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    Cell: ({ cell }) => {
      const { t } = useTranslation();
      const item = cell.row.original;
      if (item.expirationDate) {
        return item.expirationDate ?
          <IconStatus value={t(timeRemaining(item.expirationDate))} />
          : "Non défini";
      } else {
        return "Non défini";
      }
    },
  },
  
  {
    Header: "Status",
    accessor: "state",
    icon: faHourglass,
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return <IconStatus value={item.state.toString()} color={item.state.toString() === "unusedActive" ? "red" : "green"} />;
    },
  },
  {
    Header: "Order number",
    accessor: "orderNumber",
    icon: faPenNib,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.orderNumber;
    },
  },
  {
    Header: "Associated device",
    accessor: "name",
    icon: faPenNib,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.name;
    },
  },
  {
    Header: "Associated network",
    accessor: "networkName",
    icon: faPenNib,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.networkName;
    },
  },
];

export const CertificatColumn = [
  {
    accessor: "certificat",
    Header: "Certificat",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faCertificate,
  },
  {
    accessor: "type",
    Header: "Type",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faBoxOpen,
  },
  {
    accessor: "plaque",
    Header: "Plaque",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faAddressCard,
  },
  {
    accessor: "fonction",
    Header: "Fonction",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faCircleQuestion,
  },
  {
    accessor: "expiration",
    Header: "Expiration",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faBoxOpen,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (item.expiration) {
        return item.expiration ?
          <IconStatus value={convertDateAndReturn(item.expiration)} />
          : "Non défini"
      } else {
        return "Non défini";
      }
    },
  },
];

export const UmbrellaTraficColumn = [
  {
    accessor: "identities.directory_user",
    Header: "Utilisateur",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faUser,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return (
        item.identities.directory_user &&
        item.identities.directory_user.split("(")[0]
      );
    },
  },
  {
    accessor: "identities.directory_computer",
    Header: "Ordinateur",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faComputer,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return (
        item.identities.directory_computer && item.identities.directory_computer
      );
    },
  },
  {
    accessor: "identities.network",
    Header: "Network",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faEthernet,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.identities.network && <IconsStatus value={item.identities.network} />;
    },
  },
  {
    accessor: "internal_ip",
    Header: "IP interne",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faNetworkWired,
  },
  {
    accessor: "external_ip",
    Header: "IP Externe",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faSatellite,
  },
  {
    accessor: "domain",
    Header: "Domaine",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faGlobe,
  },
  {
    accessor: "categorie",
    Header: "Politique",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faShield,
  },
  {
    accessor: "date",
    Header: "Date",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faCalendar,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (item.date) {
        return item.date ?
          convertDateAndReturn(item.date)
          : "Non défini"
      } else {
        return "Non défini";
      }
    },
  },
];

export const UmbrellaRoamingColumn = [
  {
    accessor: "name",
    Header: "Nom",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
  },
  {
    accessor: "type",
    Header: "Type",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faQuestionCircle,
  },
  {
    accessor: "status",
    Header: "Status",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faPlug,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return <IconStatus value={item.status} />;
    },
  },
  {
    accessor: "lastSyncStatus",
    Header: "Dernier status",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faEye,
  },
  {
    accessor: "lastSync",
    Header: "Dernière synchr.",
    icon: faCalendar,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return elpasedTimeAndReturn(item.lastSync);
    },
  },
  {
    accessor: "version",
    Header: "Version",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faGears,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return <IconStatus value={item.version} icon />;
    },
  },
  {
    accessor: "osVersionName",
    Header: "OS",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faDesktop,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return (
        <>
          <FontAwesomeIcon
            icon={item.osVersionName.includes("Windows") ? faWindows : faApple}
          />{" "}
          {item.osVersionName}
        </>
      );
    },
  },
];

export const UmbrellaIdentiteColumn = [
  {
    accessor: "identity_name",
    Header: "Identité",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return (
        <>
          <FontAwesomeIcon
            icon={
              item.identity_type === "Sites"
                ? faEarthEurope
                : item.identity_type === "Networks"
                  ? faNetworkWired
                  : item.identity_type === "AD Users"
                    ? faUser
                    : item.identity_type === "AD Computers"
                      ? faComputer
                      : item.identity_type === "Roaming Computers"
                        ? faLaptop
                        : null
            }
          />
          <span>
            {item.identity_deleted ? "Identité supprimée" : item.identity_name}
          </span>
        </>
      );
    },
  },
  {
    accessor: "blocked",
    Header: "Requêtes bloquées",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faShield,
  },

  {
    accessor: "requests",
    Header: "Requêtes",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faPaperPlane,
  },
];

export const UmbrellaDestinationColumns = [
  {
    accessor: "domain",
    Header: "Destination",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faGlobe,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return (
        <>
          <a href={`https://${item.domain}`} target="_blank">
            {item.domain}
          </a>
        </>
      );
    },
  },
  {
    accessor: "categories",
    Header: "Catégories",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faBox,
  },
  {
    accessor: "allowed",
    Header: "Requêtes autorisées",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faDoorOpen,
  },
  {
    accessor: "blocked",
    Header: "Requêtes bloquées",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faDoorClosed,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.blocked === 0 ? "-" : item.blocked;
    },
  },
  {
    accessor: "requests",
    Header: "Requêtes",
    icon: faPaperPlane,
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
  },
];

export const UmbrellaCategoryColumn = [
  {
    accessor: "category",
    Header: "Catégorie",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faBox,
  },
  {
    accessor: "requests",
    Header: "Requêtes",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faPaperPlane,
  },
];

export const PaloLicenceColumn = [
  {
    accessor: "serial_number",
    Header: "N° de série",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faBarcode,
  },
  {
    accessor: "famille.product",
    Header: "Produit",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faInfoCircle,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return (
        <p className="table-row_cell-column">
          <img src={paloLogo} alt='Logo Palo Alto' className="table-row_cell-img" />
          {item.famille ? item.famille.product : "Non défini"}
        </p>
      );
    },
  },
  {
    accessor: "device_name",
    Header: "Nom",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
  },
  {
    accessor: "nom_license",
    Header: "Licence",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faClipboardUser,
  },
  {
    accessor: "auth_code",
    Header: "Auth code",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faLock,
  },
  {
    accessor: "date_fin",
    Header: "Expiration",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faHourglassEnd,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (item.date_fin) {
        return item.date_fin ?
          <IconStatus value={convertDateAndReturn(item.date_fin)} color={new Date(item.date_fin) > new Date() ? "green" : "red"}
          /> :
          null;
      } else {
        return null;
      }
    },
  },
];

export const PaloEquipementColumn = [
  {
    accessor: "serial_number",
    Header: "N° de série",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faBarcode,
  },
  {
    accessor: "device_name",
    Header: "Nom de l'appareil",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
  },
  {
    accessor: "ip",
    Header: "IP",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faNetworkWired,
  },
  {
    accessor: "site.nom",
    Header: "Site",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faLocationDot,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.site ? item.site.nom : "Non défini";
    },
  },
  {
    accessor: "famille.product",
    Header: "Produit",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faInfoCircle,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return (
        <p className="table-row_cell-column">
          {item.famille ? item.famille.constructeur === "CISCO" ? <img src="./assets/cisco_logo.png" className="table-row_cell-img" /> : <img src="./palo_logo.png" className="table-row_cell-img" /> : null}
          {item.famille ? item.famille.product : "Non défini"}
        </p>
      );
    },
  },
];

export const AmpColumn = [
  {
    accessor: "hostname",
    Header: "Nom de l'hôte",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faHouseUser,
  },
  {
    accessor: "operating_system",
    Header: "OS",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faWindowMaximize,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return (
        <>
          {" "}
          <FontAwesomeIcon
            icon={
              item.operating_system.includes("Windows") ? faWindows : faApple
            }
          />{" "}
          {item.operating_system ? item.operating_system : ""}
        </>
      );
    },
  },
  {
    accessor: "internal_ips",
    Header: "Ips interne",
    icon: faSatelliteDish,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.internal_ips ? (
        <MultipleStatus data={item.internal_ips} />
      ) : (
        ""
      );
    },
  },
  {
    accessor: "external_ip",
    Header: "Ip externe",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faNetworkWired,
  },
  {
    accessor: "policy.name",
    Header: "Policy",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faShield,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.policy ? <IconStatus value={item.policy.name} /> : "";
    },
  },
  {
    accessor: "last_seen",
    Header: "Dernière vue",
    icon: faEyeSlash,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.last_seen ? elpasedTimeAndReturn(item.last_seen) : "";
    },
  },
  {
    accessor: "isolation.status",
    Header: "Isolation",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faUmbrella,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.isolation.status ? (
        <IconStatus value={item.isolation.status} />
      ) : (
        ""
      );
    },
  },
];

export const UserColumn = [
  { accessor: "id", Header: "Id", icon: faCircleInfo },
  {
    accessor: "nom",
    Header: "Nom",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faSignature,
  },
  {
    accessor: "prenom",
    Header: "Prénom",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faCircleUser,
  },
  {
    accessor: "email",
    Header: "E-mail",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faEnvelope,
  },
  {
    accessor: "rolesDisplay",
    Header: "Rôles",
    icon: faAddressCard,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return (
        <MultipleStatus data={item.rolesDisplay} />
      )
    },
  },
  {
    accessor: "entreprise_name",
    Header: "Entreprise",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faBuilding,
  },
  { accessor: "holding_id", Header: "Holding", icon: faHandHolding },
];

export const SmartAccountColumn = [
  {
    accessor: "accountName",
    Header: "Nom",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faSignature,
  },
  {
    accessor: "accountDomain",
    Header: "Domaine",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faCircleInfo,
  },
  {
    accessor: "accountStatus",
    Header: "Status",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faQuestionCircle,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return <IconStatus value={item.accountStatus} />;
    },
  },
  {
    accessor: "accountType",
    Header: "Type",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
  },
  {
    accessor: "roles",
    Header: "Rôles",
    icon: faAddressCard,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return <MultipleStatus data={item.roles} />;
    },
  },
];

export const VirtualAccountColumn = [
  {
    accessor: "name",
    Header: "Nom",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faSignature,
  },
  {
    accessor: "isDefault",
    Header: "Par défaut",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faCircleInfo,
  },
  {
    accessor: "commerceAccessLevel",
    Header: "Niveau d'accès",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faLayerGroup,
  },
  {
    accessor: "description",
    Header: "Description",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faPenNib,
  },
];

export const PolyColumn = [
  {
    accessor: "node.id",
    Header: "Id",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
  },
  {
    accessor: "node.name",
    Header: "Nom",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faSignature,
  },
  {
    accessor: "node.room.name",
    Header: "Pièce",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faHouse,
  },
  {
    accessor: "node.model.name",
    Header: "Modèle",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faBarcode,
  },
  {
    accessor: "node.model.description",
    Header: "Description",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faMessage,
  },
  {
    accessor: "node.softwareVersion",
    Header: "Software version",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faGear,
  },
];

export const VisioColumn = [
  {
    accessor: "fabricant",
    Header: "Constructeur",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faPencil,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (item.famille !== null) {
        return (
          <p className="table-row_cell-column">
            <img
              src={
                item.fabriquant === "Webex"
                  ? "./assets/webex_logo.png"
                  : "./assets/poly_logo.png"
              }
              className="table-row_cell-img"
            />
            {item.fabriquant}
          </p>
        )
      } else {
        return ""
      }
    },
  },
  {
    accessor: "name",
    Header: "Nom",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faSignature,
  },
  {
    accessor: "product",
    Header: "Produit",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faBarcode,
  },
  {
    accessor: "software",
    Header: "Software",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faGear,
  },
];

export const microsoftColumn = [
  {
    accessor: "service",
    Header: "Service",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
  },

  {
    accessor: "status",
    Header: "Status",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faCircleInfo,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return <div className="status-intable"> <IconStatus value={item.status} /></div>
    },
  },
]

export const microsoftMessageColumn = [
  {
    accessor: "title",
    Header: "Titre",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
  },

  {
    accessor: "severity",
    Header: "Sévérité",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faWarning,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return <IconStatus value={item.severity} />
    },
  },

  {
    accessor: "isMajorChange",
    Header: "Changement majeur",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faCircleInfo,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.isMajorChange === true ?
        <div className="status-intable"><IconStatus value="OUI" /></div> :
        <div className="status-intable"><IconStatus value="NON" /></div>
    },
  },
  {
    accessor: "services",
    Header: "Services",
    icon: faHandHolding,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return <MultipleStatus data={item.services} />
    },
  },

  {
    accessor: "lastModifiedDateTime",
    Header: "Dernière modification",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faCircleInfo,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (item.lastModifiedDateTime) {
        return item.lastModifiedDateTime ?
          elpasedTimeAndReturn(item.lastModifiedDateTime)
          : "Non défini"
      } else {
        return "Non défini";
      }
    },
  },
]


export const webexIoTColumn = [
  {
    accessor: "displayName",
    Header: "Nom",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
  },

  {
    accessor: "type",
    Header: "Type",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faCircleInfo,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.type ? <div className="status-intable"><IconStatus value={item.type} /></div> : null
    },
  },
  {
    accessor: "capacity",
    Header: "Capacité",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faPeopleRoof,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return item.capacity ? <div className="status-intable"><IconStatus value={item.capacity} /></div> : null;
    },
  },
  {
    accessor: "created",
    Header: "Créé le",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faClockRotateLeft,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      const date = convertDateAndReturn(item.created)
      return item.created ? date : null
    },
  },
]


export const SubscriptionColumn = [
  {
    accessor: "subscriptionId",
    Header: "id",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
  },

  {
    accessor: "subscriptionStatus",
    Header: "Statuts",
    Filter: DropdownFilter,
    filter: "rankedMatchSorter",
    icon: faCircleInfo,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return <div className="status-intable"><IconStatus value={item.subscriptionStatus} /></div>
    },
  },
  {
    accessor: "offerType",
    Header: "Type d'offre",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faHandHolding,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      return <div className="status-intable"><IconStatus value={item.offerType} /></div>
    },
  },
  {
    accessor: "duration",
    Header: "Durée",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faHourglassHalf,
    Cell: ({ cell }) => {
      const { t } = useTranslation();
      const item = cell.row.original;
      return <div className="status-intable">{item.duration} {t('mois')}</div>
    },
  },
  {
    accessor: "startDate",
    Header: "Date de début",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faClockRotateLeft,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (item.startDate) {
        return item.startDate ?
          convertDateAndReturn(item.startDate)
          : "Non défini"
      } else {
        return "Non défini";
      }
    },
  },
  {
    accessor: "endDate",
    Header: "Date de fin",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faClockRotateLeft,
    Cell: ({ cell }) => {
      const item = cell.row.original;
      if (item.endDate) {
        return item.endDate ?
          <IconStatus value={convertDateAndReturn(item.endDate)} color={new Date(item.endDate) > new Date() ? "green" : "red"} />
          : <IconStatus value={"Non défini"} />
      } else {
        return <IconStatus value={"Non défini"} />;
      }
    },
  },
  {
    accessor: "transactionType",
    Header: "Type de transaction",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faDollar,
  },
]

export const ProvisionningColumn = [
  {
    accessor: "name",
    Header: "Nom",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
    Cell: ({ cell }) => {
      const item = cell.row.original.name;
      return <div className="status-intable"><IconStatus value={item} color={"normal"} /></div>
    },
  },
  {
    accessor: "serial_number",
    Header: "Serial_Number",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faBarcode,
    Cell: ({ cell }) => {
      const item = cell.row.original.serial_number;
      if (item) {
        const formattedItem = item.split(",").join(", ");
        const splitItems = item.split(",");
        const displayValue = splitItems.length > 2 ? `${splitItems.slice(0, 2).join(", ")} ...` : formattedItem;
        return item ?
          <div className="status-intable"><IconStatus value={displayValue} color={"normal"} /></div>
          : <IconStatus value={"Non défini"} />
      } else {
        return <IconStatus value={"Non défini"} />;
      }
    },
  },
  {
    accessor: "endDate",
    Header: "Date de fin",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faClockRotateLeft,
    Cell: ({ cell }) => {
      const item = cell.row.original.endDate;
      if (item) {
        return item ?
          <IconStatus value={convertDateAndReturn(item)} color={new Date(item) > new Date() ? "no-expired-status-color" : "red"} />
          : <IconStatus value={"Non défini"} />
      } else {
        return <IconStatus value={"Non défini"} />;
      }
    },
  },
  {
    accessor: "source",
    Header: "Source",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faHandHolding,
    Cell: ({ cell }) => {
      const item = cell.row.original.source;
      return <div className="status-intable"><IconStatus value={item} color={item === 'subscription' ? 'subscription-status-color' : item === 'pare-feu' ? 'firewall-status-color' : item === 'certificat' ? 'certificat-status-color' : item === 'contract' ? 'contract-status-color' : '' } /></div>
    },
  },
]

export const ArrowSphereAllColumn = [
  {
    accessor: "license_id",
    Header: "id",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
  },
  {
    accessor: "name",
    Header: "Nom",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
    Cell: ({ cell }) => {
      const item = cell.row.original.name;
      return <div className="status-intable"><IconStatus value={item} color={"normal"} /></div>
    },
  },
  {
    accessor: "Date start",
    Header: "Date d'activation",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faClockRotateLeft,
    Cell: ({ cell }) => {
      const item = cell.row.original.activation_datetime;
      if (item) {
        return item ?
          <IconStatus value={convertDateAndReturn(item)} color={"green"} />
          : <IconStatus value={"Non défini"} />
      } else {
        return <IconStatus value={"Non défini"} />;
      }
    },
  },
  {
    accessor: "endDate",
    Header: "Date de fin",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faClockRotateLeft,
    Cell: ({ cell }) => {
      const item = cell.row.original.expiry_datetime;
      if (item) {
        return item ?
          <IconStatus value={convertDateAndReturn(item)} color={new Date(item) > new Date() ? "green" : "red"} />
          : <IconStatus value={"Non défini"} />
      } else {
        return <IconStatus value={"Non défini"} />;
      }
    },
  },
  {
    accessor: "periodicity",
    Header: "périodicité",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
    Cell: ({ cell }) => {
      const item = cell.row.original.periodicity;
      return <div className="status-intable"><IconStatus value={item} color={"normal"} /></div>
    },
  },
  {
    accessor: "term",
    Header: "term",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
    Cell: ({ cell }) => {
      const item = cell.row.original.term;
      return <div className="status-intable"><IconStatus value={item} color={"normal"} /></div>
    },
  },
]

export const ArrowSphereClientColumn = [
  {
    accessor: "license_id",
    Header: "id",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
  },
  {
    accessor: "name",
    Header: "Nom",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
    Cell: ({ cell }) => {
      const item = cell.row.original.name;
      return <div className="status-intable"><IconStatus value={item} color={"normal"} /></div>
    },
  },
  {
    accessor: "Date start",
    Header: "Date d'activation",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faClockRotateLeft,
    Cell: ({ cell }) => {
      const item = cell.row.original.activation_datetime;
      if (item) {
        return item ?
          <IconStatus value={convertDateAndReturn(item)} color={"green"} />
          : <IconStatus value={"Non défini"} />
      } else {
        return <IconStatus value={"Non défini"} />;
      }
    },
  },
  {
    accessor: "endDate",
    Header: "Date de fin",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faClockRotateLeft,
    Cell: ({ cell }) => {
      const item = cell.row.original.expiry_datetime;
      if (item) {
        return item ?
          <IconStatus value={convertDateAndReturn(item)} color={new Date(item) > new Date() ? "green" : "red"} />
          : <IconStatus value={"Non défini"} />
      } else {
        return <IconStatus value={"Non défini"} />;
      }
    },
  },
  {
    accessor: "periodicity",
    Header: "périodicité",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
    Cell: ({ cell }) => {
      const item = cell.row.original.periodicity;
      return <div className="status-intable"><IconStatus value={item} color={"normal"} /></div>
    },
  },
  {
    accessor: "term",
    Header: "term",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
    Cell: ({ cell }) => {
      const item = cell.row.original.term;
      return <div className="status-intable"><IconStatus value={item} color={"normal"} /></div>
    },
  },
]

export const WebexStatisticsColumn = [
  {
    accessor: "starttime",
    Header: "Date de début",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    filterable: false,
    headerColumn: true
  },
  {
    accessor: "location",
    Header: "Localisation",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    filterable: true,
    headerColumn: true
  },
  {
    accessor: "callingnumber",
    Header: "Numéro appelant",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    filterable: true,
    headerColumn: true
  },
  {
    accessor: "callednumber",
    Header: "Numéro appelé",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    filterable: true,
    headerColumn: true
  },
  {
    accessor: "calledlineid",
    Header: "Ligne appelée",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    filterable: true,
    headerColumn: true
  },
  {
    accessor: "callinglineid",
    Header: "Ligne appelante",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    filterable: true,
    headerColumn: true
  },
  {
    accessor: "calloutcome",
    Header: "Résultat de l'appel",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    filterable: true,
    headerColumn: true
  },
  {
    accessor: "calltype",
    Header: "Type d'appel",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    filterable: true,
    headerColumn: true
  },
  {
    accessor: "duration",
    Header: "Durée (s)",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    filterable: true,
    headerColumn: true
  },
  {
    accessor: "answerindicator",
    Header: "Indicateur de réponse",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    filterable: true,
    headerColumn: false
  },
  {
    accessor: "isAnswered",
    Header: "Indicateur de réponse",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    headerColumn: true
  },
];

export const AgentInsightcolumns = [
  {
    accessor: "name",
    Header: "name",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faUser,
  },
  {
    accessor: "department_name",
    Header: "department_name",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faCircleInfo,
  },
]

export const TicketsInsightcolumns = [
  {
    accessor: "ticket_id",
    Header: "id du ticket",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faBarcode,
  },
  {
    accessor: "team",
    Header: "team",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faUser,
  },
  {
    accessor: "ticket_type",
    Header: "type",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faTag,
    Cell: ({ cell }) => {
      const ticketType = cell.row.original.ticket_type;
      return ticketType ? (
        <IconStatus 
          value={ticketType} 
          color={ticketType === "Project Task" || ticketType === "Project" || ticketType === "Risk (Projects)" || ticketType === "Quick Quote" || ticketType === "Quick Time" || ticketType === "Change Request" || ticketType === "Lead"
            ? "green" // Vert pastel
            : ticketType === "Scheduled Task"
            ? "blue_pastel" // Bleu pastel
            : ticketType === "Problem" || ticketType === "Incident" || ticketType === "Alert"
            ? "peche_pastel" // Pêche pastel
            : ticketType === "Firewall Change Request" || ticketType === "Administrator Rights Request" || ticketType === "Shared Drive Access Request" || ticketType === "Service Request"
            ? "lilas_pastel" // Lilas pastel
            : ticketType === "New Starter Request" || ticketType === "Leaver Request"
            ? "pink_pastel" // Rose pastel
            : ticketType === "Peripherals Request" || ticketType === "Mobile Phone Request" || ticketType === "Laptop Request" || ticketType === "Desktop Request" || ticketType === "Hardware Collection Request" || ticketType === "Print Services Request"
            ? "yellow_pastel" // Jaune pastel
            : ticketType === "Software Change" || ticketType === "Software Request"
            ? "green_water_pastel" // Vert d’eau pastel
            : ticketType === "Other Hardware" || ticketType === "Network Request"
            ? "blue_lavande_pastel" // Bleu lavande pastel
            : ""
          }/>
      ) : (
        <IconStatus value={ticketType}/>
      );
    },
  },
  {
    accessor: "summary",
    Header: "résumé du ticket",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faCircleInfo,
  },
  {
    accessor: "date_action",
    Header: "date d'action",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faClock,
  },
  {
    accessor: "date_creation_ticket",
    Header: "date de création du ticket",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faClock,
  },
  {
    accessor: "time_spent",
    Header: "temps de travail",
    Filter: TextSearchFilter,
    filter: "rankedMatchSorter",
    icon: faClockRotateLeft,
    Cell: ({ cell }) => {
      const timeSpent = cell.row.original.time_spent;
      const formatTimeSpent = (minutes) => {
        if (minutes >= 60) {
          const hours = Math.floor(minutes / 60);
          const remainingMinutes = minutes % 60;
          return remainingMinutes > 0 
            ? `${hours}h${remainingMinutes}min`
            : `${hours}h`;
        } else {
          return `${minutes}min`;
        }
      };

      return formatTimeSpent(timeSpent);
      }
  }
];