import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import Loader from "../../components/Loader";
import { useQuery } from "@tanstack/react-query";
import TableComponent from "../../components/TableComponent";
import { Contractscolumns} from "../../components/headerContent";
import NumberCard from "../../components/Card/NumberCard";
import RowContract from "./RowContract";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

export default function Contract() {
  const { apiUrl, user } = useContext(AuthContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const contractId = searchParams.get("contractId");
  const referrer = searchParams.get("referrer");
  const [filteredData, setFilteredData] = useState([]);
  const {t} = useTranslation();
  
  const getContract = async () => {
    const res = await axios.get(`${apiUrl}contract/byserial`, {
      withCredentials: true,
    });
    if(res.data === "No data") {
      return [res.data];
    } else {
      return res.data.filter(item => !item.contract_number.includes("Contract"));
    }
  };

  const contract = useQuery({
    queryKey: ["contracts"],
    queryFn: getContract,
  });

  useEffect(() => {
    if (contract.data !== undefined && !contract.data.includes("No data")) {
      if (contractId) {
        const filteredData = contract.data.filter(cont => cont.contract_number === contractId);
        setFilteredData(filteredData);
      } else {
        setFilteredData(contract.data);
      }
    }
  }, [contract.data, contractId]);

  if (contract.data && contract.data.includes("No data") || contract.data && contract.data.length === 0) {
    return (
      <article className="page">
        <h1 className="page-title">Aucun Contrats</h1>
      </article>
    );
  }

  const exportCSV = () => {
    axios.get(`${apiUrl}contract/export`, {withCredentials: true, 
      responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'contrats.csv';
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {});
  };

  if(referrer) {
    contract.refetch();
  }

  return (
    <>
      {!contract.isLoading ? (
        <>
          <article className="page">
            <div className="page-logo">
                <img src="./assets/cisco_logo.png" />
                <h1 className="page-title">Smartnet</h1>
                {user.roles.includes("ROLE_SUPERADMIN") && <button className="button button-new" onClick={exportCSV}>{t('Exporter')}</button>}

            </div>

            <section className="page-section">
              <NumberCard
                title="Nombre de contrats"
                number={
                  filteredData.length
                }
              />
            </section>
            {contractId && (
              <div className="page-title page-link-timeline">
              <Link to={`/${referrer}`} className="button button-new"><FontAwesomeIcon icon={faChevronLeft}/> {t('Retour')}</Link>
              </div>
            )}
            <TableComponent
              tableData={
               filteredData
              }
              rowComponent={RowContract}
              tableColumns={Contractscolumns}
              checkbox={false}
            />
          </article>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
