import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsStaggered,
} from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useState } from "react";
import menuData from "./menuData";
import Categorie from "./Categorie";
import { AuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

/**
 * 
 * @param {boolean} sideOpen - to toggle sidebar
 * @param {setSideOpen} setSideOpen - to change value of sideOpen
 */

export default function Sidebar({ sideOpen, setSideOpen }) {
  const [activeBtn, setActiveBtn] = useState(null);
  const [secondDrop, setSecondDrop] = useState(null);
  const [thirdDrop, setThirdDrop] = useState(null);
  const [showCategorie, setShowCategorie] = useState(false);
  const [categorieStyle, setCategorieStyle] = useState(null);
  const [titleStyle, setTitleStyle] = useState(null);
  const { t } = useTranslation();
  const { user } = useContext(AuthContext)

  useEffect(() => {
    if (!sideOpen) {
      setShowCategorie(false);
      setCategorieStyle({ transition: "all 300ms ease-in-out" });
    } else {
      setShowCategorie({ left: null });
    }
  }, [sideOpen]);

  // Handle level of menu
  const handleactiveBtn = (id) => {
    if (activeBtn !== null) {
      setTitleStyle(null)
    } else if (activeBtn === null) {
      setTitleStyle({ transition: "none" })
    }
    if (activeBtn !== id) {
      setActiveBtn(id);
      setSideOpen(true);
      setSecondDrop(null);
      setThirdDrop(null);
      setShowCategorie(true);
      setCategorieStyle({ transition: "all 300ms ease-in-out" });
    } else if (activeBtn === id && !sideOpen) {
      setSideOpen(!sideOpen);
    } else if (activeBtn === id && sideOpen) {
      setActiveBtn(null);
      setCategorieStyle({ transition: "all 300ms ease-in-out" });
    }
  };

  const handleSecondDrop = (id) => {
    if (secondDrop === id && activeBtn) {
      setSecondDrop(null);
    } else if (secondDrop !== id && activeBtn) {
      setSecondDrop(id);
    }
  };

  const handleThirdDrop = (id) => {
    if (thirdDrop === id && secondDrop) {
      setThirdDrop(null);
    } else if (secondDrop !== id && secondDrop) {
      setThirdDrop(id);
    }
  };

  return (
    <>
      {user &&
        <nav className="sidebar">
          <div
            className={`sidebar-container ${sideOpen ? "sidebar-container_open" : ""}`}
          >
            <button
              aria-label="bouton pour ouvrir le menu"
              className={`sidebar-burger ${sideOpen ? "sidebar-burger_open" : ""}`}
              onClick={() => setSideOpen(!sideOpen)}
            >
              <FontAwesomeIcon className="sidebar-icon" icon={faBarsStaggered} />
            </button>
            <ul className="sidebar-list">
              {menuData.map((menu, index) => {
                return (
                  !menu.dontDisplay && <div key={index}>
                    <li
                      className={`sidebar-list_item ${activeBtn === `drop${index + 1}btn`
                        ? "sidebar-list_item-active"
                        : ""
                        }`}
                      onClick={() => {
                        handleactiveBtn(`drop${index + 1}btn`);
                      }}
                    >
                      <a className="sidebar-list_link">
                        {menu.icon}
                        <span className={`sidebar-list_link-title${activeBtn ? "-none" : ""}`} style={titleStyle}>
                          {t(menu.item)}
                        </span>
                      </a>
                    </li>
                    <div
                      className={`categorie ${activeBtn === `drop${index + 1}btn` && showCategorie
                        ? "categorie-show"
                        : ""
                        }`}
                      style={categorieStyle}
                    >
                      <h2>{t(menu.item)}</h2>
                      <ul className="categorie-list">
                        {menu.category.map((category, index) => {
                          return (
                            <Categorie
                              key={index}
                              category={category}
                              activeBtn={activeBtn}
                              index={index}
                              secondDrop={secondDrop}
                              handleSecondDrop={handleSecondDrop}
                              thirdDrop={thirdDrop}
                              handleThirdDrop={handleThirdDrop}
                            />
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </ul>
          </div>
        </nav>
      }
    </>
  );
}