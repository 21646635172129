import React, { useState, useContext } from "react";
import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header";
import Webex from "../pages/Webex/Webex";
import Certificats from "../pages/Certificats/Certificats";
import Equipement from "../pages/Equipements/Equipement";
import AmpTable from "../pages/AMP/AmpTable";
import Incidents from "../pages/Incidents/Incidents";
import AgentInsight from "../pages/AgentInsight/AgentInsight";
import Reporting from "../pages/Reporting/Reporting";
import AmpEvents from "../pages/AMP/AmpEvents";
import Licence from "../pages/PaloAlto/Licence";
import EquipementPalo from "../pages/PaloAlto/EquipementPalo";
import Home from "../pages/Home";
import Umbrella from "../pages/Umbrella/Umbrella";
import Meraki from "../pages/Meraki/Meraki";
import Auvik from "../pages/Auvik/Auvik";
import User from "../pages/Users/User";
import SmartAccount from "../pages/SmartAccount/SmartAccount";
import Poly from "../pages/Poly/Poly";
import Visio from "../pages/Visio/Visio";
import FileManager from "../pages/FileManager/FileManager";
import Microsoft from "../pages/Microsoft/Microsoft";
import IoT from "../pages/IoT/IoT";
import Error404 from "../pages/Error404";
import MerakiDevice from "../pages/Meraki/MerakiDevice";
import AuvikDevice from "../pages/Auvik/AuvikDevice";
import InterfaceAuvik from "../pages/Auvik/InterfaceAuvik";
import Contract from "../pages/Contract/Contract";
import Subscription from "../pages/Subscription/Subscription";
import AuvikAlerts from "../pages/Auvik/AuvikAlerts";
import MerakiLicences from "../pages/Meraki/MerakiLicences";
import TimelineEcp from "../pages/Timeline/TimelineEcp";
import StatisticsWebex from "../pages/Webex/Statistics/StatisticsWebex";
import Parking from "../pages/Parking/Parking";
import Logs from "../pages/Logs/Logs";

export default function RouterEcp({ entrepriseRoles }) {
    const [sideOpen, setSideOpen] = useState(false);
    const { user } = useContext(AuthContext);
    
    return (
        <Router>
            <Header/>
            <Sidebar sideOpen={sideOpen} setSideOpen={setSideOpen} />
            <div className={`${sideOpen ? "container-open" : "container"}`}>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    {entrepriseRoles.data.includes("ROLE_INCIDENT") && (
                        <Route exact path="/tickets" element={<Incidents />} />
                    )}
                    {entrepriseRoles.data.includes("ROLE_EQUIPEMENT") && (
                        <Route exact path="/equipements" element={<Equipement />} />
                    )}
                    {entrepriseRoles.data.includes("ROLE_EQUIPEMENT") && (
                        <Route exact path="/smartnet" element={<Contract />} />
                    )}
                    {entrepriseRoles.data.includes("ROLE_AMP") && (
                        <Route
                            exact
                            path="/secure_endpoint/equipement"
                            element={<AmpTable />}
                        />
                    )}
                    {entrepriseRoles.data.includes("ROLE_AMP") && (
                        <Route
                            exact
                            path="/secure_endpoint/events"
                            element={<AmpEvents />}
                        />
                    )}
                    {entrepriseRoles.data.includes("ROLE_CERTIFICATS") && (
                        <Route
                            exact
                            path="/certificats"
                            element={<Certificats />}
                        />
                    )}
                    {entrepriseRoles.data.includes("ROLE_WEBEX") && (
                        <Route exact path="/webex" element={<Webex />} />
                    )}
                    {entrepriseRoles.data.includes("ROLE_ANALYTICS") && (
                        <Route exact path="/hardware" element={<Reporting visio={false} />} />
                    )}
                    {entrepriseRoles.data.includes("ROLE_PALO") && (
                        <Route
                            exact
                            path="/licences/paloalto"
                            element={<Licence />}
                        />
                    )}
                    {entrepriseRoles.data.includes("ROLE_PALO") && (
                        <Route
                            exact
                            path="/firewall"
                            element={<EquipementPalo />}
                        />
                    )}
                    {entrepriseRoles.data.includes("ROLE_UMBRELLA") && (
                        <Route exact path="/umbrella" element={<Umbrella />} />
                    )}
                    {entrepriseRoles.data.includes("ROLE_MERAKI") && (
                        <Route exact path="/meraki" element={<Meraki />} />
                    )}
                    {entrepriseRoles.data.includes("ROLE_MERAKI") && (
                        <Route
                            exact
                            path="/meraki/:networkId"
                            element={<MerakiDevice />}
                        />
                    )}
                    {entrepriseRoles.data.includes("ROLE_MERAKI") && (
                        <Route
                            exact
                            path="/meraki/licenses"
                            element={<MerakiLicences/>}
                        />
                    )}
                    <Route exact path="/users" element={<User />} />
                    {entrepriseRoles.data.includes("ROLE_SMARTACCOUNT") && (
                        <Route
                            exact
                            path="/smart_account"
                            element={<SmartAccount />}
                        />
                    )}
                    {entrepriseRoles.data.includes("ROLE_POLY") && (
                        <Route exact path="/poly" element={<Poly />} />
                    )}
                    {(entrepriseRoles.data.includes("ROLE_WEBEX") ||
                        entrepriseRoles.data.includes("ROLE_POLY")) && (
                            <Route
                                exact
                                path="/visio"
                                element={<Equipement visio={true} />}
                            />
                        )}
                    <Route
                        exact
                        path="/files_manager"
                        element={<FileManager />}
                    />
                    {entrepriseRoles.data.includes("ROLE_0365") && (
                        <Route exact path="/microsoft" element={<Microsoft />} />
                    )}
                    {(entrepriseRoles.data.includes("ROLE_WEBEX") ||
                        entrepriseRoles.data.includes("ROLE_MERAKI")) && (
                            <Route exact path="/IoT" element={<IoT />} />
                        )}
                    {entrepriseRoles.data.includes("ROLE_AUVIK") && (
                        <Route exact path="/auvik" element={<Auvik />} />
                    )}
                    {entrepriseRoles.data.includes("ROLE_AUVIK") && (
                        <Route
                            exact
                            path="/auvik/:tenantId"
                            element={<AuvikDevice />}
                        />
                    )}
                    {entrepriseRoles.data.includes("ROLE_AUVIK") && (
                        <Route
                            exact
                            path="/auvik/:tenantId/alerts"
                            element={<AuvikAlerts />}
                        />
                    )}
                    {entrepriseRoles.data.includes("ROLE_AUVIK") && (
                        <Route
                            exact
                            path="/auvik/:tenantId/:interfaceId"
                            element={<InterfaceAuvik />}
                        />
                    )}
                    {user.roles.includes("ROLE_SUPERADMIN") && (
                        <Route
                            exact
                            path="/timeline"
                            element={<TimelineEcp />}
                        />
                    )}
                    {user.roles.includes("ROLE_SUPERADMIN") && (
                        <Route
                            exact
                            path="/statistics"
                            element={<StatisticsWebex />}
                        />
                    )}
                    {user.roles.includes("ROLE_PARKING") && (
                        <Route
                            exact
                            path="/parking"
                            element={<Parking />}
                        />
                    )}
                    {user.roles.includes("ROLE_SUPERADMIN") && (
                        <Route 
                            exact 
                            path="/agentinsight" 
                            element={<AgentInsight />} />
                    )}
                    {user.roles.includes("ROLE_SUPERADMIN") && (
                        <Route 
                            exact 
                            path="/logs" 
                            element={<Logs />} />
                    )}
                    <Route exact path="/subscription" element={<Subscription />} />
                    <Route path="/*" element={<Error404 />} />
                </Routes>
            </div>
        </Router>
    );
}