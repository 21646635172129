import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import TableComponent from "../../components/TableComponent";
import { LogsMonitoringColumns } from "../../components/headerContent";
import NumberCard from "../../components/Card/NumberCard";
import CarousselNav from "../../components/Card/CarousselNav";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";

export default function Logs() {
    const { apiUrl } = useContext(AuthContext);
    const [flattenedLogs, setFlattenedLogs] = useState([]);
    const valuesType = ["Tous", "Elit-Technologies", "Autres"];
    const [currentSlide, setCurrentSlide] = useState(0);
    const { t } = useTranslation();

    const getLogs = async () => {
        try {
            const res = await axios.get(`${apiUrl}log/index`, {
                withCredentials: true,
            });
            return res.data;
        } catch (error) {
            console.error("Failed to fetch logs:", error);
            return { logs: [] };
        }
    };

    const logs = useQuery({
        queryKey: ["logs"],
        queryFn: getLogs,
    });

    // Filter logs based on the current slide
    useEffect(() => {
        if (logs.data?.logs) {
            let logsFiltered;

            switch (valuesType[currentSlide]) {
                case "Elit-Technologies":
                    logsFiltered = logs.data.logs.filter((log) => {
                        const email = log.extra?.userData?.email;
                        return email && email.endsWith("@elit-technologies.fr");
                    });
                    break;

                case "Autres":
                    logsFiltered = logs.data.logs.filter((log) => {
                        const email = log.extra?.userData?.email;
                        return !email || !email.endsWith("@elit-technologies.fr");
                    });
                    break;

                default:
                    logsFiltered = logs.data.logs;
                    break;
            }

            const newFlattenedData = logsFiltered.map((log) => {
                const { message, ...rest } = log;
                return {
                    ...rest,
                    log: message,
                };
            });

            setFlattenedLogs(newFlattenedData);
        }
    }, [logs.data?.logs, currentSlide]);

    if (!logs.isLoading && (!logs.data?.logs || logs.data.logs.length === 0)) {
        return <p>Aucune données disponible</p>;
    }

    return (
        <>
            {!logs.isLoading ? (
                <article className="page">
                    <section className="page-logo">
                        <h1 className="page-title">Logs</h1>
                        <CarousselNav
                            arrayValues={valuesType}
                            currentSlide={currentSlide}
                            setCurrentSlide={setCurrentSlide}
                        />
                    </section>
                    {flattenedLogs?.length > 0 && (
                        <NumberCard
                            title="Tickets"
                            number={flattenedLogs.length}
                        />
                    )}
                    {flattenedLogs?.length > 0 && (
                        <TableComponent
                            tableData={flattenedLogs}
                            tableColumns={LogsMonitoringColumns}
                        />
                    )}
                </article>
            ) : (
                <Loader />
            )}
        </>
    );
}